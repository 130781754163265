import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHome, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import '../styles/Header.css'; // Add styles here
import logo from '../assets/logo192.png'; // Import the logo

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="user-menu">
        <Link to="/" className="home-icon">
          <FontAwesomeIcon icon={faHome} size="lg" />
        </Link>
        <div className="user-info" onClick={handleDropdownToggle}>
          <span>{user?.name}</span>
          <FontAwesomeIcon icon={faUser} size="lg" className="account-icon" />
          <FontAwesomeIcon icon={faCaretDown} size="lg" />
        </div>
        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
          <Link to="/" onClick={() => setDropdownOpen(false)}>Home</Link>
          <Link to="/account" onClick={() => setDropdownOpen(false)}>Account</Link>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
