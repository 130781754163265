import React, { useState, useEffect } from 'react';
import axios from 'axios';
import forms from '../assets/forms.json'; // Import the JSON file
import '../styles/RolePermissions.css'; // Add styles here

const RolePermissions = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [newRoleName, setNewRoleName] = useState('');

  const rolesAPI = `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_ROLES}`;

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(rolesAPI);
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setPermissions(role.permissions);
  };

  const handlePermissionChange = (formKey, permissionType, value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [formKey]: {
        ...prevPermissions[formKey],
        [permissionType]: value
      }
    }));
  };

  const handleSavePermissions = async () => {
    try {
      await axios.put(`${rolesAPI}/${selectedRole.id}`, {
        ...selectedRole,
        permissions
      });
      alert('Permissions updated successfully.');
      fetchRoles();
    } catch (error) {
      console.error('Error saving permissions:', error);
      alert('Failed to update permissions.');
    }
  };

  const handleAddRole = async () => {
    try {
      const newRole = {
        name: newRoleName,
        permissions: {}
      };
      const response = await axios.post(`${rolesAPI}`, newRole);
      setRoles([...roles, response.data]);
      setNewRoleName('');
      alert('Role added successfully.');
    } catch (error) {
      console.error('Error adding role:', error);
      alert('Failed to add role.');
    }
  };

  return (
    <div className="role-permissions-container">
      <h2>Role Permissions</h2>
      <div className="roles-list">
        {roles.map((role) => (
          <button
            key={role.id}
            onClick={() => handleRoleChange(role)}
            className={selectedRole?.id === role.id ? 'active' : ''}
          >
            {role.name}
          </button>
        ))}
      </div>
      {selectedRole && (
        <div className="permissions-form">
          <h3>Permissions for {selectedRole.name}</h3>
          {forms.map((form) => (
            <div key={form.key} className="form-permissions">
              <h4>{form.name}</h4>
              {['View', 'Edit', 'Add', 'Delete'].map((permissionType) => (
                <label key={permissionType}>
                  <input
                    type="checkbox"
                    checked={permissions[form.key]?.[permissionType] === 'true'}
                    onChange={(e) =>
                      handlePermissionChange(form.key, permissionType, e.target.checked.toString())
                    }
                  />
                  {permissionType}
                </label>
              ))}
            </div>
          ))}
          <button onClick={handleSavePermissions} className="btn-save">Save Permissions</button>
        </div>
      )}
      <div className="add-role">
        <h3>Add New Role</h3>
        <input
          type="text"
          value={newRoleName}
          onChange={(e) => setNewRoleName(e.target.value)}
          placeholder="Role Name"
        />
        <button onClick={handleAddRole} className="btn-add">Add Role</button>
      </div>
    </div>
  );
};

export default RolePermissions;
