import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SalesForm from './SalesForm';
import '../styles/SalesQueue.css';
import { useAuth } from '../hooks/useAuth';

const SalesQueue = ({ initialSale }) => {
  const { rolePermissions } = useAuth();
  const [queue, setQueue] = useState([]);
  const [selectedSale, setSelectedSale] = useState(null);
  const [showQueue, setShowQueue] = useState(false);

  useEffect(() => {
    const savedQueue = JSON.parse(localStorage.getItem('salesQueue')) || [];
    setQueue(savedQueue);

    if (initialSale) {
      fetchSaleDetails(initialSale.sales_id);
    }
  }, [initialSale]);

  const fetchSaleDetails = async (salesId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_SALES_TRANSACTIONS}/${salesId}`);
      console.log('The line items are: ', response.data);
      const headerItems = { ...response.data.header[0] };
      const recordToEdit = { header: headerItems, items: response.data.lineItems };
      console.log("retrieved items: ", recordToEdit);
      setSelectedSale(recordToEdit);
    } catch (error) {
      console.error('Error fetching sale details:', error);
    }
  };

  const handleSave = (salesData) => {
    const newQueue = [...queue];
    const saleIndex = newQueue.findIndex(sale => sale.header.sales_id === salesData.header.sales_id);

    if (saleIndex !== -1) {
      newQueue[saleIndex] = { ...salesData, synced: false };
    } else {
      newQueue.push({ ...salesData, synced: false });
    }

    setQueue(newQueue);
    localStorage.setItem('salesQueue', JSON.stringify(newQueue));
    setSelectedSale(null);
  };

  const handleEdit = (sale) => {
    setSelectedSale(sale);
  };

  const handleDelete = (id) => {
    const updatedQueue = queue.filter((item) => item.header.sales_id !== id);
    localStorage.setItem('salesQueue', JSON.stringify(updatedQueue));
    setQueue(updatedQueue);
  };

  const toggleQueue = () => {
    setShowQueue(!showQueue);
  };

  const allSynced = queue.every(sale => sale.synced);
  const hasDeletePermission = rolePermissions['/sales-queue']?.Delete === 'true';

  return (
    <div className="queue-and-form">
      <div className="queue-header">
        <div className="queue-controls">
          <span className={`sync-indicator ${allSynced ? 'green' : 'red'}`}></span>
          <button className="toggle-button" onClick={toggleQueue}>
            {showQueue ? 'Hide Status' : 'Show Status'}
          </button>
        </div>
      </div>
      {showQueue && (
        <div className="sales-queue-container">
          <h2>Sales Queue</h2>
          <ul className="sales-queue-list">
            {queue.map((sale, index) => (
              <li key={index} className="sales-queue-item">
                <div>
                  <span><strong>Patient Name:</strong> {sale.header.patient_name}</span>
                  <span><strong>Type:</strong> {sale.header.sales_type}</span>
                  <span><strong>Branch:</strong> {sale.header.branch}</span>
                  <span><strong>Date:</strong> {sale.header.sales_date}</span>
                  <span><strong>Status:</strong> {sale.synced ? 'Synced' : 'Not Synced'}</span>
                </div>
                <div>
                  <button className="edit-button" onClick={() => handleEdit(sale)}>Edit</button>
                  {hasDeletePermission && (
                    <button className="delete-button" onClick={() => handleDelete(sale.header.sales_id)}>Delete</button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <SalesForm salesData={selectedSale} onSave={handleSave} />
    </div>
  );
};

export default SalesQueue;
