import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [firebaseUID, setFirebaseUID] = useState('');
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');
  const [editing, setEditing] = useState(false);

  const userAPI = `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_USERS}`;
  useEffect(() => {
    console.log("entering user management");
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await axios.get(userAPI);
    setUsers(response.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editing) {
      await axios.put(`${userAPI}/${userId}`, { name: userName, email: userEmail, role: userRole });
      setEditing(false);
    } else {
      await axios.post(userAPI, { firebase_uid: firebaseUID, name: userName, email: userEmail, role: userRole });
    }
    setFirebaseUID('');
    setUserId('');
    setUserName('');
    setUserEmail('');
    setUserRole('');
    fetchUsers();
  };

  const handleEdit = (user) => {
    setFirebaseUID(user.firebase_uid);
    setUserId(user.id);
    setUserName(user.name);
    setUserEmail(user.email);
    setUserRole(user.role);
    setEditing(true);
  };

  const handleDelete = async (id) => {
    await axios.delete(`${userAPI}/${id}`);
    fetchUsers();
  };

  const handleInactivate = async (id) => {
    await axios.patch(`${userAPI}/${id}/inactivate`);
    fetchUsers();
  };

  return (
    <div>
      <h2>User Management</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Firebase UID:</label>
          <input
            type="text"
            value={firebaseUID}
            onChange={(e) => setFirebaseUID(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Role:</label>
          <input
            type="text"
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
            required
          />
        </div>
        <button type="submit">{editing ? 'Update' : 'Add'} User</button>
      </form>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} ({user.email}) - {user.role}
            <button onClick={() => handleEdit(user)}>Edit</button>
            <button onClick={() => handleInactivate(user.id)}>Inactivate</button>
            <button onClick={() => handleDelete(user.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserManagement;
