// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyBAKv5iHLN2qvHQUdyCqdbwX-hgmLZDt_c",
  authDomain: "shuman-hospital-apps.firebaseapp.com",
  projectId: "shuman-hospital-apps",
  storageBucket: "YOUR_PROJECT_ID.appspot.com",
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
  appId: "1:522357105652:web:1d1d63b404bdd21b178fa1",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set persistence
setPersistence(auth, browserLocalPersistence).then(() => {
    console.log('Firebase Auth state persistence set to browser local persistence.');
  }).catch((error) => {
    console.error('Error setting Firebase Auth state persistence:', error);
  });

export { auth };
