
export function getPrevMonthStartDate() {
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return formatDate(firstDayOfMonth);
  }

export function getPrevMonthEndDate() {
    const date = new Date();
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 0);
    return formatDate(lastDayOfMonth);
  }

/* take a date in the format of 2024-01-31 and returns 31 Jan 2024*/
export function formatDateToZoho(dateString) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const [year, month, day] = dateString.split('-');
    const monthIndex = parseInt(month, 10) - 1; // Month index starts from 0
    
    return `${parseInt(day, 10)} ${months[monthIndex]} ${year}`;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

 