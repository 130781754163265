import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/MedicalReports.css';

const MedicalReportsForm = ({ patient, onSave, onClose, viewOnly = false }) => {
  const [formData, setFormData] = useState(patient || {
    customer: '',
    batchName: '',
    shId: '',
    name: '',
    serialNumber: '',
    bloodSugar: '',
    hba1: '',
    kidneyFunctionTest: 'Normal',
    liverFunctionTest: 'Normal',
    cholesterolProfile: 'Normal',
    hb: '',
    tuberculosis: 'Not Shown',
    rvs: 'Negative',
    chestXRay: 'Normal',
    ecg: 'Normal',
    hbsAg: 'Negative',
    hcv: 'Negative',
    psaQuantitative: '',
    urineAnalysis: 'Normal',
    audiometryTest: 'Normal',
    doctorsComment: ''
  });

  useEffect(() => {
    setFormData(patient);
  }, [patient]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (patient.id) {
        await axios.put(`/hospital-portal/api/medicalreports/${patient.id}`, formData);
      } else {
        await axios.post(`/hospital-portal/api/medicalreports`, formData);
      }
      onSave();
    } catch (error) {
      console.error('Error saving patient:', error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-group">
            <label>Customer:</label>
            <input type="text" name="customer" value={formData.customer} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>Batch Name:</label>
            <input type="text" name="batchName" value={formData.batchName} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>SH ID:</label>
            <input type="text" name="shId" value={formData.shId} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>Serial Number:</label>
            <input type="text" name="serialNumber" value={formData.serialNumber} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>Blood Sugar:</label>
            <input type="number" name="bloodSugar" value={formData.bloodSugar} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>HBA 1%:</label>
            <input type="number" step="0.1" name="hba1" value={formData.hba1} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>Kidney Function Test:</label>
            <select name="kidneyFunctionTest" value={formData.kidneyFunctionTest} onChange={handleChange} disabled={viewOnly}>
              <option value="Normal">Normal</option>
              <option value="Doubtful">Doubtful</option>
            </select>
          </div>
          <div className="form-group">
            <label>Liver Function Test:</label>
            <select name="liverFunctionTest" value={formData.liverFunctionTest} onChange={handleChange} disabled={viewOnly}>
              <option value="Normal">Normal</option>
              <option value="Doubtful">Doubtful</option>
            </select>
          </div>
          <div className="form-group">
            <label>Cholesterol Profile:</label>
            <select name="cholesterolProfile" value={formData.cholesterolProfile} onChange={handleChange} disabled={viewOnly}>
              <option value="Normal">Normal</option>
              <option value="Doubtful">Doubtful</option>
            </select>
          </div>
          <div className="form-group">
            <label>HB:</label>
            <input type="number" step="0.1" name="hb" value={formData.hb} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>Tuberculosis:</label>
            <select name="tuberculosis" value={formData.tuberculosis} onChange={handleChange} disabled={viewOnly}>
              <option value="Shown">Shown</option>
              <option value="Not Shown">Not Shown</option>
            </select>
          </div>
          <div className="form-group">
            <label>RVS:</label>
            <select name="rvs" value={formData.rvs} onChange={handleChange} disabled={viewOnly}>
              <option value="Recommended">Recommended</option>
              <option value="Negative">Negative</option>
            </select>
          </div>
          <div className="form-group">
            <label>Chest X-Ray:</label>
            <select name="chestXRay" value={formData.chestXRay} onChange={handleChange} disabled={viewOnly}>
              <option value="Normal">Normal</option>
              <option value="Doubtful">Doubtful</option>
            </select>
          </div>
          <div className="form-group">
            <label>ECG:</label>
            <select name="ecg" value={formData.ecg} onChange={handleChange} disabled={viewOnly}>
              <option value="Normal">Normal</option>
              <option value="Doubtful">Doubtful</option>
            </select>
          </div>
          <div className="form-group">
            <label>HBS/AG:</label>
            <select name="hbsAg" value={formData.hbsAg} onChange={handleChange} disabled={viewOnly}>
              <option value="Positive">Positive</option>
              <option value="Negative">Negative</option>
            </select>
          </div>
          <div className="form-group">
            <label>HCV:</label>
            <select name="hcv" value={formData.hcv} onChange={handleChange} disabled={viewOnly}>
              <option value="Positive">Positive</option>
              <option value="Negative">Negative</option>
            </select>
          </div>
          <div className="form-group">
            <label>PSA Quantitative:</label>
            <input type="number" step="0.01" name="psaQuantitative" value={formData.psaQuantitative} onChange={handleChange} disabled={viewOnly} />
          </div>
          <div className="form-group">
            <label>Urine Analysis:</label>
            <select name="urineAnalysis" value={formData.urineAnalysis} onChange={handleChange} disabled={viewOnly}>
              <option value="Normal">Normal</option>
              <option value="Doubtful">Doubtful</option>
            </select>
          </div>
          <div className="form-group">
            <label>Audiometry Test:</label>
            <select name="audiometryTest" value={formData.audiometryTest} onChange={handleChange} disabled={viewOnly}>
              <option value="Normal">Normal</option>
              <option value="Doubtful">Doubtful</option>
            </select>
          </div>
          <div className="form-group">
            <label>Doctor's Comment:</label>
            <textarea name="doctorsComment" value={formData.doctorsComment} onChange={handleChange} disabled={viewOnly}></textarea>
          </div>
          <div className="form-buttons">
            <button type="button" onClick={onClose}>Close</button>
            {!viewOnly && <button type="submit">Save</button>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MedicalReportsForm;
