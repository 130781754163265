import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CustomerInvoices from './CustomerInvoices';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/BulkInvoice.css';
import CustomerPicker from './CustomerPicker';
import DataSyncButton from './DataSyncButton'; // Import the DataSyncButton

const BulkInvoice = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customerName, setCustomerName] = useState('');
  const [invoicesData, setInvoicesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [datesConfirmed, setDatesConfirmed] = useState(false); // Track if dates are confirmed
  const [isSyncing, setIsSyncing] = useState(false);

  const endDateRef = useRef(null); // Ref for end date picker to focus on it after selecting start date

  const handleCustomerSelect = (selectedCustomer) => {
    setCustomerName(selectedCustomer);
  };

  const handleSearch = async () => {
    if (!startDate || !endDate || !customerName) {
      alert('Please fill out all fields');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_SALES_TRANSACTIONS}`, {
        params: {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          customerName: customerName,
          status: 'unpaid',
        },
      });

      if (response.status === 200) {
        setInvoicesData(response.data);
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartDateChange = (date) => {
    console.log("Selected Date:",date);
    setStartDate(date);
    setDatesConfirmed(false); // Reset the dates confirmation
    setEndDate(date); // Clear the end date
    setInvoicesData([]); //clear invoices
    endDateRef.current.setFocus(); // Focus on the end date picker
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setDatesConfirmed(true); // Confirm the dates when the end date is selected
  };

  const handleSyncStart = () => {
    setIsSyncing(true);
    console.log('Sync started...');
  };

  const handleSyncComplete = () => {
    setIsSyncing(false);
    console.log('Sync completed.');
  };
  return (
    <div className="bulk-invoice-container">      
      <div className="invoice-form">
        <div className="form-group">
          <label>Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="yyyy-MM-dd"
            required
          />
        </div>
        <div className="form-group">
          <label>End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="yyyy-MM-dd"
            required
            ref={endDateRef} // Use the ref to focus on this picker after start date is selected
          />
        </div>
        <div className="form-group">
          <CustomerPicker onCustomerSelect={handleCustomerSelect} />
        </div>

        <div className="form-actions">
          <button onClick={handleSearch} disabled={isLoading || !datesConfirmed || !customerName || isSyncing}>
            {isLoading ? 'Loading...' : 'Create Invoice'}
          </button>
        </div>
        <div className="sync-data-container">
        <DataSyncButton 
          startDate={startDate} 
          endDate={endDate} 
          customerName={customerName} 
          recordType="Invoices"
          onSyncStart={handleSyncStart}
          onSyncComplete={handleSyncComplete}/>
      </div>
      </div>

      {invoicesData.length > 0 && !isLoading && (
        <CustomerInvoices invoicesData={invoicesData} customerName={customerName} startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};

export default BulkInvoice;
