import React from 'react';
import PropTypes from 'prop-types';

const LabTestValue = ({ value, minValue, maxValue, failValue }) => {
  // Determine the styles based on the provided values
  const isOutOfRange = (minValue !== undefined && maxValue !== undefined && (value < minValue || value > maxValue));
  const isFailValue = (minValue === undefined || maxValue === undefined) && (value === failValue);

  const style = {
    backgroundColor: isOutOfRange || isFailValue ? 'red' : 'white',
    color: isOutOfRange || isFailValue ? 'white' : 'black',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center',
    fontWeight: 'bold'
  };

  return (
    <div style={style}>
      {value}
    </div>
  );
};

// Define PropTypes for the component
LabTestValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  failValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

// Define default props for the component
LabTestValue.defaultProps = {
  minValue: undefined,
  maxValue: undefined,
  failValue: undefined
};

export default LabTestValue;
