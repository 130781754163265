import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import MedicalReportsForm from './components/MedicalReportsForm';
import './styles/MedicalReports.css';
import LabTestValue from './components/LabTestValue';
import { useAuth } from './hooks/useAuth'; // Import the useAuth hook

const MedicalReportsList = () => {
  const { user, rolePermissions } = useAuth(); // Get user and permissions
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [editingPatient, setEditingPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/medicalreports`);
      console.log("medical path:",'${process.env.REACT_APP_SERVER_BASE_URL}/api/medicalreports');
      setPatients(response.data);
      setFilteredPatients(response.data); // Initialize filteredPatients with the fetched data
      setLoading(false);
    } catch (error) {
      console.error('Error fetching patients:', error);
      setLoading(false);
    }
  };

  const handleEdit = (patient) => {
    setEditingPatient(patient);
  };

  const handleAdd = () => {
    setEditingPatient({}); // Set to empty object for new record
  };

  const handleSave = () => {
    setEditingPatient(null);
    setSelectedPatient(null); // Deselect patient after saving
    fetchPatients();
  };

  const handleRowClick = (patient) => {
    setSelectedPatient(patient);
  };

  const handleClose = () => {
    setEditingPatient(null);
    setSelectedPatient(null);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredData = patients.filter((patient) =>
      patient.name.toLowerCase().includes(query) ||
      patient.customer.toLowerCase().includes(query) ||
      patient.batchName.toLowerCase().includes(query)
    );
    setFilteredPatients(filteredData);
  };

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Customer',
      selector: row => row.customer,
      sortable: true,
    },
    {
      name: 'Batch Name',
      selector: row => row.batchName,
      sortable: true,
    },
    {
      name: 'Blood Sugar',
      cell: row => <LabTestValue value={row.bloodSugar} minValue={0} maxValue={120} />,
      sortable: true,
    },
    {
      name: 'HBA1',
      cell: row => <LabTestValue value={row.hba1} minValue={0} maxValue={120} />,
      sortable: true,
    },
    {
      name: 'Kidney Function',
      cell: row => <LabTestValue value={row.kidneyFunctionTest} failValue="Doubtful" />,
      sortable: true,
    },
    // Conditionally add the 'Actions' column if the user has 'Edit' permission
    ...(rolePermissions['/medical-reports']?.Edit === 'true' ? [{
      name: 'Actions',
      cell: row => <button onClick={() => handleEdit(row)}>Edit</button>,
    }] : [])
  ];

  return (
    <div className="container">
      <div>
        <h1>Medicals Report</h1>
        {rolePermissions['/medical-reports']?.Add === 'true' && (
          <button className="add-button" onClick={handleAdd}>+ Add</button>
        )}
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-input"
        />
        {editingPatient ? (
          <MedicalReportsForm patient={editingPatient} onSave={handleSave} onClose={handleClose} />
        ) : selectedPatient ? (
          <MedicalReportsForm patient={selectedPatient} viewOnly onSave={() => setSelectedPatient(null)} onClose={handleClose} />
        ) : (
          <DataTable
            columns={columns}
            data={filteredPatients}
            progressPending={loading}
            pagination
            highlightOnHover
            pointerOnHover
            onRowClicked={handleRowClick}
          />
        )}
      </div>
    </div>
  );
};

export default MedicalReportsList;
