import React, { useState, useEffect } from 'react';
import '../styles/SalesForm.css';
import { useAuth } from '../hooks/useAuth';

const SalesForm = ({ salesData, onSave }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    header: {
      sales_type: 'Invoice',
      customer_name: '',
      branch: 'Pultney',
      patient_name: '',
      patient_id: '',
      patient_mobile: '',
      sales_date: new Date().toISOString().split('T')[0],
      created_by: user.id,
      updated_by: user.id,
      created_date: new Date().toISOString(),
      updated_date: new Date().toISOString()
    },
    items: [],
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (salesData) {
      setFormData(salesData);
      console.log('SalesForm received salesdata:', salesData);
    }
  }, [salesData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      header: {
        ...prevFormData.header,
        [name]: value,
        updated_by: user.id,
        updated_date: new Date().toISOString()
      }
    }));
    setSaveDisabled(false);
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...formData.items];
    newItems[index][field] = value;
    if (field === 'line_price' || field === 'line_quantity') {
      newItems[index].line_total = (newItems[index].line_price * newItems[index].line_quantity).toFixed(2);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      header: {
        ...prevFormData.header,
        updated_by: user.id,
        updated_date: new Date().toISOString()
      },
      items: newItems,
    }));
    setSaveDisabled(false);
  };

  const handleAddItem = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      items: [
        ...prevFormData.items,
        { description: '', price: '', quantity: '', total: '0.00' },
      ],
      header: {
        ...prevFormData.header,
        updated_by: user.id,
        updated_date: new Date().toISOString()
      }
    }));
    setSaveDisabled(false);
  };

  const validateForm = () => {
    const newErrors = {};

    if (formData.header.sales_type === 'Invoice' && !formData.header.customer_name) {
      newErrors.customer_name = 'Customer is required for Invoices';
    }

    if (!formData.header.patient_name) {
      newErrors.patient_name = 'Patient Name is required';
    }

    if (!formData.header.patient_id) {
      newErrors.patient_id = 'Patient ID is required';
    }

    if (!formData.header.sales_date) {
      newErrors.sales_date = 'Sales Date is required';
    }

    if (formData.items.length === 0) {
      newErrors.lineItems = 'At least one line item is needed to save an invoice.';
    }
    formData.items.forEach((item, index) => {
      if (!item.line_description) {
        newErrors[`item-description-${index}`] = 'Description is required';
      }
      if (!item.line_price || item.line_price <= 0) {
        newErrors[`item-price-${index}`] = 'Price must be greater than 0';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(formData);
      setMessage('Record added to the queue successfully.');
      setSaveDisabled(true);
    } else {
      setMessage('Error saving the record. Please check the form and try again.');
    }
  };

  const handleCancel = () => {
    if (saveDisabled || window.confirm('You have unsaved changes. Are you sure you want to cancel?')) {
      setFormData({
        header: {
          sales_type: 'Invoice',
          customer_name: '',
          branch: 'Pultney',
          patient_name: '',
          patient_id: '',
          patient_mobile: '',
          sales_date: new Date().toISOString().split('T')[0],
          created_by: user.id,
          updated_by: user.id,
          created_date: new Date().toISOString(),
          updated_date: new Date().toISOString()
        },
        items: [],
      });
      setErrors({});
      setMessage('');
    }
  };

  const handleAddNew = () => {
    if (saveDisabled || window.confirm('You have unsaved changes. Are you sure you want to add a new record?')) {
      setFormData({
        header: {
          sales_type: 'Invoice',
          customer_name: '',
          branch: 'Pultney',
          patient_name: '',
          patient_id: '',
          patient_mobile: '',
          sales_date: new Date().toISOString().split('T')[0],
          created_by: user.id,
          updated_by: user.id,
          created_date: new Date().toISOString(),
          updated_date: new Date().toISOString()
        },
        items: [],
      });
      setErrors({});
      setMessage('');
      setSaveDisabled(true);
    }
  };

  return (
    <div className="sales-form-container">
      {message && <div className={`message ${message.includes('Error') ? 'error' : 'success'}`}>{message}</div>}
      <div className="form-header">
        <h2>{salesData ? 'Edit Sales' : 'Add Sales'}</h2>
      </div>
      <form>
        <div className="form-column">
          <div className="form-group">
            <label>Type:</label>
            <select name="sales_type" value={formData.header.sales_type} onChange={handleInputChange}>
              <option value="Invoice">Invoice</option>
              <option value="Sales Receipt">Sales Receipt</option>
            </select>
          </div>
          {formData.header.sales_type === 'Invoice' && (
            <div className="form-group">
              <label>Customer:</label>
              <input
                type="text"
                name="customer_name"
                value={formData.header.customer_name}
                onChange={handleInputChange}
                required
              />
              {errors.customer_name && <span className="error">{errors.customer_name}</span>}
            </div>
          )}
          <div className="form-group">
            <label>Branch:</label>
            <select name="branch" value={formData.header.branch} onChange={handleInputChange}>
              <option value="Pultney">Pultney</option>
              <option value="Kissy St">Kissy St</option>
            </select>
          </div>
          <div className="form-group">
            <label>Sales Date:</label>
            <input
              type="date"
              name="sales_date"
              value={formData.header.sales_date}
              onChange={handleInputChange}
              required
            />
            {errors.sales_date && <span className="error">{errors.sales_date}</span>}
          </div>
        </div>
        <div className="form-column">
          <div className="form-group">
            <label>Patient Name:</label>
            <input
              type="text"
              name="patient_name"
              value={formData.header.patient_name}
              onChange={handleInputChange}
              required
            />
            {errors.patient_name && <span className="error">{errors.patient_name}</span>}
          </div>
          <div className="form-group">
            <label>Patient ID:</label>
            <input
              type="text"
              name="patient_id"
              value={formData.header.patient_id}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Patient Mobile:</label>
            <input
              type="text"
              name="patient_mobile"
              value={formData.header.patient_mobile}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="line-items">
          <h3>Line Items</h3>
          {formData.items.map((item, index) => (
            <div key={index} className="line-item">
              <input
                type="text"
                placeholder="Description"
                value={item.line_description}
                onChange={(e) => handleItemChange(index, 'line_description', e.target.value)}
                required
              />
              {errors[`item-description-${index}`] && <span className="error">{errors[`item-description-${index}`]}</span>}
              <input
                type="number"
                placeholder="Price"
                value={item.line_price}
                onChange={(e) => handleItemChange(index, 'line_price', e.target.value)}
                required
                min="0.01"
              />
              {errors[`item-price-${index}`] && <span className="error">{errors[`item-price-${index}`]}</span>}
              <input
                type="number"
                placeholder="Qty"
                value={item.line_quantity}
                onChange={(e) => handleItemChange(index, 'line_quantity', e.target.value)}
                required
                min="1"
              />
              <input
                type="text"
                placeholder="Total"
                value={item.line_total}
                readOnly
              />
            </div>
          ))}
          {errors.lineItems && <span className="error">{errors.lineItems}</span>}
          <button type="button" onClick={handleAddItem}>Add Item</button>
        </div>
        <div className="form-group">
          <label>Invoice Total: Sle{formData.items.reduce((sum, item) => sum + parseFloat(item.line_total), 0).toFixed(2)}</label>
        </div>
        <div className="form-actions">
          <button type="button" className={`save-button ${saveDisabled ? 'disabled' : ''}`} onClick={handleSave} disabled={saveDisabled}>Save</button>
          <button type="button" onClick={handleCancel}>Cancel</button>
          <button type="button" onClick={handleAddNew}>Add New</button>
        </div>
      </form>
    </div>
  );
};

export default SalesForm;
