import React, { useEffect, useState } from 'react';
import axios from 'axios';
//import './CustomerPicker.css'; // Assuming you will have a separate CSS file for styling

const CustomerPicker = ({ onCustomerSelect }) => {
  const [customerNames, setCustomerNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomerNames, setFilteredCustomerNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownSize, setDropdownSize] = useState(1);
  const [searchActive, setSearchActive] = useState(false);

  const serverURL = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    const fetchCustomerNames = async () => {
        const status = 'Active';
      setIsLoading(true);
      try {
        const response = await axios.get(`${serverURL}/hospital-portal/api/customers`, {
            params: {
              status: status
            }
        });
        setCustomerNames(response.data);
      } catch (error) {
        console.error('Error fetching customer names:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomerNames();
  }, []);

  useEffect(() => {
    setFilteredCustomerNames(
      customerNames.filter((customer) =>
        customer.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [customerNames, searchTerm]);

  const handleCustomerChange = (event) => {
    onCustomerSelect(event.target.value);
    setSearchTerm('');
    setSearchActive(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setDropdownSize(event.target.value ? filteredCustomerNames.length + 1 : 1);
    setSearchActive(true);
  };

  return (
    <div className="customer-picker">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="search-container">
            <label htmlFor="search">Search Customer:</label>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search for customer..."
            />
          </div>
          <div className="select-container">

            <select 
              id="customer" 
              onChange={handleCustomerChange}
              size={searchActive ? dropdownSize : 1 }
            >
              <option value="">-- Select Customer --</option>
              {filteredCustomerNames.map((customer) => (
                <option key={customer.customer_name} value={customer.customer_name}>
                  {customer.customer_name}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerPicker;
