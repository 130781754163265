import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MedicalReportsList from './MedicalReportsList';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './hooks/useAuth';
import Header from './components/Header';
import UserManagement from './components/UserManagement';
import RolePermissions from './components/RolePermissions';
import LandingPage from './components/LandingPage';
import Account from './components/Account';
import SalesTransactions from './components/SalesTransactions';
import SalesForm from './components/SalesForm';
import SalesQueue from './components/SalesQueue';
import BulkInvoice from './components/BulkInvoice';
import DoctorSalesSummary from './components/DoctorSalesSummary';
import DoctorSalesDetails from './components/DoctorSalesDetails';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/account" element={<Account />} />
          </Route>
          <Route path="/medical-reports" element={<PrivateRoute />}>
            <Route path="/medical-reports" element={<MedicalReportsList />} />
          </Route>
          <Route path="/sales-form" element={<PrivateRoute />}>
            <Route path="/sales-form" element={<SalesForm />} />
          </Route>
          <Route path="/sales-queue" element={<PrivateRoute />}>
            <Route path="/sales-queue" element={<SalesQueue />} />
          </Route>
          <Route path="/user-management" element={<PrivateRoute requiredRoles={['Admin']} requiredPermissions={['View']} />}>
            <Route path="/user-management" element={<UserManagement />} />
          </Route>
          <Route path="/role-management" element={<PrivateRoute requiredRoles={['Admin']} requiredPermissions={['View']} />}>
            <Route path="/role-management" element={<RolePermissions />} />
          </Route>
          <Route path="/sales-transactions" element={<PrivateRoute />}>
            <Route path="/sales-transactions" element={<SalesTransactions />} />
          </Route>
          <Route path="/customer-invoice" element={<PrivateRoute />}>
            <Route path="/customer-invoice" element={<BulkInvoice />} />
          </Route>
          <Route path="/doctor-sales-summary" element={<PrivateRoute />}>
            <Route path="/doctor-sales-summary" element={<DoctorSalesSummary/>} />
          </Route>
          <Route path="/doctor-sales-details" element={<PrivateRoute />}>
            <Route path="/doctor-sales-details" element={<DoctorSalesDetails/>} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
