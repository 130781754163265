import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/DataSyncButton.css'; // Add CSS for the button styling

const DataSyncButton = ({ startDate, endDate, customerName, recordType, onSyncStart, onSyncComplete }) => {
  const [syncStatus, setSyncStatus] = useState(null); // Sync status ('Clean' or 'Dirty')
  const [isBackupRunning, setIsBackupRunning] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false); // Control visibility of the sync button
  const [backupStatus, setBackupStatus] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false); // Loading status for sync check

  // Function to check sync status
  const checkDataSync = async () => {
    if (!customerName) return; // Avoid triggering if no customer is provided
    setIsLoadingStatus(true);
    setBackupStatus(null);
    setSyncStatus(null); // Clear any previous sync status message
    try {
      const response = await axios.get(`${process.env.REACT_APP_ZOHO_SERVER_BASE_URL}/checkDataSync`, {
        params: {
          date_start: startDate.toISOString().split('T')[0],
          date_end: endDate.toISOString().split('T')[0],
          customer_name: customerName,
          record_type: recordType,
        },
      });
      setSyncStatus(response.data.status);
      setIsButtonVisible(response.data.status === 'Dirty');
    } catch (error) {
      console.error('Error checking sync status:', error);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  // Function to handle sync button click
  const handleBackup = async () => {
    setIsBackupRunning(true);
    setBackupStatus('Backup Running…');

    // Notify parent that sync has started
   // if (onSyncStart) {
      onSyncStart();
  //    console.log("Setting Sync to Start");
  //  }

    try {
      await axios.get(`${process.env.REACT_APP_ZOHO_SERVER_BASE_URL}/backupData`, {
        params: {
          date_start: startDate.toISOString().split('T')[0],
          date_end: endDate.toISOString().split('T')[0],
          customer_name: customerName,
        },
      });

      setBackupStatus('Backup Completed');
      setIsBackupRunning(false);
      checkDataSync(); // Re-check the sync status after backup

      // Notify parent that sync is complete
     // if (onSyncComplete) {
        onSyncComplete();
     // }
    } catch (error) {
      console.error('Error starting backup:', error);
      setBackupStatus('Backup Failed');
      setIsBackupRunning(false);

      // Notify parent that sync is complete, even if it failed
      if (onSyncComplete) {
        onSyncComplete();
      }
    }
  };

  // Check sync status when component mounts or props change
  useEffect(() => {
    if (customerName && startDate && endDate) checkDataSync();
  }, [startDate, endDate, customerName]);

  return (
    <div className="data-sync-container">
      {isLoadingStatus && <p>Getting Status...</p>}
      
      {/* Show message only after loading */}
      {!isLoadingStatus && syncStatus && (
        <p style={{ color: syncStatus === 'Dirty' ? 'red' : 'green' }}>
          {syncStatus === 'Dirty' ? 'Data is not in sync with Zoho!' : 'Data is up to date!'}
        </p>
      )}

      {isButtonVisible && (
        <div style={{ display: 'block' }}>
          <button
            onClick={handleBackup}
            className={isBackupRunning ? 'backup-button disabled' : 'backup-button'}
            disabled={isBackupRunning}
          >
            {isBackupRunning ? 'Syncing Data...' : 'Sync Data'}
          </button>
        </div>
      )}

      {backupStatus && <p style={{ marginTop: '10px' }}>{backupStatus}</p>}
    </div>
  );
};

export default DataSyncButton;
