import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/DoctorSalesSummary.css';

const DoctorSalesSummary = () => {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(localStorage.getItem('selectedDoctor') || ''); // Preserve doctor
  const [startDate, setStartDate] = useState(localStorage.getItem('startDate') ? new Date(localStorage.getItem('startDate')) : new Date()); // Preserve startDate
  const [endDate, setEndDate] = useState(localStorage.getItem('endDate') ? new Date(localStorage.getItem('endDate')) : new Date()); // Preserve endDate
  const [salesData, setSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();  

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_GET_DOCTORS}`);
        setDoctors(response.data.doctors);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_DOCTOR_SALES}`, {
        params: {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          doctorName: selectedDoctor,
        },
      });
      setSalesData(response.data);
    } catch (error) {
      console.error('Error fetching doctor sales summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTotal = (row) => {
    const total =
      parseFloat(row.Total_Consultations || 0) +
      parseFloat(row.Total_Admissions || 0) +
      parseFloat(row.Total_Observations || 0) +
      parseFloat(row.Total_Surgeries || 0) +
      parseFloat(row.Total_Lab_Medications || 0);
    return total;
  };

  const handleShowCommission = (row) => {
    
    localStorage.setItem('selectedDoctor', selectedDoctor);
    localStorage.setItem('startDate', startDate.toISOString());
    localStorage.setItem('endDate', endDate.toISOString());
    navigate(`/doctor-sales-details?doctor=${row.doctor_name}&startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}`);
  };

  const handleShowDetail = (row) => {
    localStorage.setItem('selectedDoctor', selectedDoctor);
    localStorage.setItem('startDate', startDate.toISOString());
    localStorage.setItem('endDate', endDate.toISOString());
    navigate(`/doctor-sales-details?doctor=${row.doctor_name}&startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}`);
  };

  const columns = [
    { name: 'Doctor Name', selector: row => row.doctor_name, sortable: true },
    { 
      name: 'Total', 
      cell: (row) => calculateTotal(row),
      sortable: true,
      sortFunction: (rowA, rowB) => calculateTotal(rowB) - calculateTotal(rowA),
      style: {
        backgroundColor: '#f2f2f2',
        fontWeight: 'bold',
      }
    },
    { name: 'Consultations', selector: row => row.Total_Consultations, sortable: true },
    { name: 'Admissions', selector: row => row.Total_Admissions, sortable: true },
    { name: 'Observations', selector: row => row.Total_Observations, sortable: true },
    { name: 'Surgeries', selector: row => row.Total_Surgeries, sortable: true },
    { name: 'Lab & Medication', selector: row => row.Total_Lab_Medications, sortable: true },
    { 
      name: '', 
      cell: (row) => (
        <button onClick={() => handleShowCommission(row)} className="icon-button">
          <FontAwesomeIcon icon={faDollarSign} />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    { 
      name: '', 
      cell: (row) => (
        <button onClick={() => handleShowDetail(row)} className="icon-button">
          <FontAwesomeIcon icon={faInfoCircle} />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  return (
    <div className="doctor-sales-summary">
      <h2>Doctor Sales Summary</h2>
      <div className="filter-container">
        <div className="filter-group">
          <label>Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="filter-group">
          <label>End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="filter-group">
          <label>Select Doctor:</label>
          <select
            value={selectedDoctor}
            onChange={(e) => setSelectedDoctor(e.target.value)}
          >
            <option value="">All Doctors</option>
            {doctors.map((doctor) => (
              <option key={doctor.name} value={doctor.name}>
                {doctor.name}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-group">
          <button onClick={handleSearch} disabled={isLoading}>
            {isLoading ? 'Running Report...' : 'Run Report'}
          </button>
        </div>
      </div>

      <div className="data-table-container">
        <DataTable
          columns={columns}
          data={salesData}
          pagination
          highlightOnHover
          progressPending={isLoading}
          defaultSortField="Total"
          defaultSortAsc={false}
        />
      </div>

      {salesData.length > 0 && (
        <CSVLink
          data={salesData}
          filename={`doctor_sales_summary_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.csv`}
          className="btn btn-primary"
        >
          Export to CSV
        </CSVLink>
      )}
    </div>
  );
};

export default DoctorSalesSummary;
