import '../styles/InvoiceItem.css'; // Import the CSS file for styling
import React from 'react';

const InvoiceItem = ({ invoice }) => {
  const productTypes = {};
  
  if(invoice.Consultation > 0)
    productTypes['Consultation Fees'] = parseFloat(invoice.Consultation);
  if(invoice.Admission > 0)
    productTypes['Admission Fees'] = parseFloat(invoice.Admission);
  if(invoice.Observation > 0)
    productTypes['Observation Fees'] = parseFloat(invoice.Observation);
  if(invoice.Surgery > 0)
    productTypes['Surgery Fees'] = parseFloat(invoice.Surgery);
  if(invoice['Lab and Medication'] > 0)
    productTypes['Lab and Medication'] = parseFloat(invoice['Lab and Medication']);

  return (
    <div className='itemsSections'>
      <table className="invoice-table">
        <tbody>
          {
            Object.keys(productTypes).map((key) => (
              <tr key={key}>
                <td className='typeCell'>{key}</td>
                <td>{productTypes[key].toFixed(2)}</td>
              </tr>
            ))
        }
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceItem;
